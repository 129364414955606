import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EntityManagementService } from './entity-management/services/base/entity-management.service';
import { AppointmentsEntityService } from './entity-services/appointments-entity.service';
import { AuthStatementsEntityService } from './entity-services/auth-statements-entity.service';
import { CyclesEntityService } from './entity-services/cycles-entity.service';
import { ExamParsesEntityService } from './entity-services/exam-parses-entity.service';
import { ExamRequestsEntityService } from './entity-services/exam-requests-entity.service';
import { FindingEntityService } from './entity-services/finding-entity.service';
import { FormAnswersEntityService } from './entity-services/form-answers-entity.service';
import { FormSubmissionsEntityService } from './entity-services/form-submissions-entity.service';
import { OrganizationEntityService } from './entity-services/organization-entity.service';
import { PerformedExamsEntityService } from './entity-services/performed-exams-entity.service';
import { PersonEntityService } from './entity-services/person-entity.service';
import { PersonImportEntityService } from './entity-services/person-import-entity.service';
import { PersonRegistrationsEntityService } from './entity-services/person-registrations-entity.service';
import { PreviousAllergiesEntityService } from './entity-services/previous-allergies-entity.service';
import { PreviousDiseasesEntityService } from './entity-services/previous-diseases-entity.service';
import { PreviousMedicationsEntityService } from './entity-services/previous-medications-entity.service';
import { RecipientValidationCodesEntityService } from './entity-services/recipient-validation-codes-entity.service';
import { ReferralEntityService } from './entity-services/referral-entity.service';
import { SpecialtyEntityService } from './entity-services/specialty-entity.service';
import { SubscriptionEntityService } from './entity-services/subscription-entity.service';
import { TokenEntityService } from './entity-services/token-entity.service';

@NgModule({
  providers: [],
  imports: [CommonModule],
})
export class StateModule {
  constructor(
    appointmentsEntityService: AppointmentsEntityService,
    authStatementsEntityService: AuthStatementsEntityService,
    cyclesEntityService: CyclesEntityService,
    examParsesEntityService: ExamParsesEntityService,
    examRequestsEntityService: ExamRequestsEntityService,
    findingEntityService: FindingEntityService,
    formAnswersEntityService: FormAnswersEntityService,
    formSubmissionsEntityService: FormSubmissionsEntityService,
    organizationEntityService: OrganizationEntityService,
    performedExamsEntityService: PerformedExamsEntityService,
    personEntityService: PersonEntityService,
    personImportEntityService: PersonImportEntityService,
    personRegistrationsEntityService: PersonRegistrationsEntityService,
    previousAllergiesEntityService: PreviousAllergiesEntityService,
    previousDiseasesEntityService: PreviousDiseasesEntityService,
    previousMedicationsEntityService: PreviousMedicationsEntityService,
    recipientValidationCodesEntityService: RecipientValidationCodesEntityService,
    referralEntityService: ReferralEntityService,
    specialtyEntityService: SpecialtyEntityService,
    subscriptionEntityService: SubscriptionEntityService,
    tokenEntityService: TokenEntityService,
    entityManagementService: EntityManagementService
  ) {
    entityManagementService.registrerServices([
      appointmentsEntityService,
      authStatementsEntityService,
      cyclesEntityService,
      examParsesEntityService,
      examRequestsEntityService,
      findingEntityService,
      formAnswersEntityService,
      formSubmissionsEntityService,
      organizationEntityService,
      performedExamsEntityService,
      personEntityService,
      personImportEntityService,
      personRegistrationsEntityService,
      previousAllergiesEntityService,
      previousDiseasesEntityService,
      previousMedicationsEntityService,
      recipientValidationCodesEntityService,
      referralEntityService,
      specialtyEntityService,
      subscriptionEntityService,
      tokenEntityService,
    ]);
  }
}
