import { ErrorHandler, LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';

import * as Sentry from '@sentry/angular';

import { AppComponent } from './app.component';
import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';

import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import {
  StateModule,
} from './state/state.module';
import { NavigationService } from './shared/services/navigation.service';
import { FlexModule } from '@angular/flex-layout';
import { FormModule } from './form/form.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

registerLocaleData(localeBr, 'pt');

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        StateModule,
        RouterModule.forRoot(rootRouterConfig, {
            useHash: false,
        }),
        FlexModule,
        FormModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressBarModule], providers: [
        NavigationService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt',
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
