import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, Subject, switchMap, take, throwError } from 'rxjs';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { catchError, filter, finalize } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new Subject<any>();
  private authService;

  constructor(private jwtAuth: JwtAuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.jwtAuth.token || this.jwtAuth.getJwtToken();
    let changedReq;

    const excludeUrlS3 = '.s3.us-east-2.amazonaws.com';
    const excludeToken = '/v1/token';
    if (
      token &&
      req.url.indexOf(excludeUrlS3) === -1 &&
      req.url.indexOf(excludeToken) === -1
    ) {
      changedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      changedReq = req;
    }
    return next.handle(changedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (req.url.includes('token')) {
          if (this.refreshTokenInProgress) {
            this.jwtAuth.signout();
            return EMPTY;
          }
          return throwError(error);
        }
        if (error && error.status === 401) {
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready, and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addTokenHeader(changedReq))),
            );
          } else {
            if (this.jwtAuth.getJwtRefreshToken()) {
              this.refreshTokenInProgress = true;
              return this.jwtAuth.refreshToken().pipe(
                switchMap(() => {
                  this.refreshTokenSubject.next(true);
                  return next.handle(this.addTokenHeader(changedReq));
                }),
              );
            } else {
              this.jwtAuth.signout();
            }
          }
        }
        return throwError(error);
      }),
    );
  }

  addTokenHeader(req: HttpRequest<any>) {
    const token = this.jwtAuth.token || this.jwtAuth.getJwtToken();
    const excludeUrlS3 = '.s3.us-east-2.amazonaws.com';
    const excludeToken = '/v1/token';
    if (
      token &&
      req.url.indexOf(excludeUrlS3) === -1 &&
      req.url.indexOf(excludeToken) === -1
    ) {
      return req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return req;
  }
}
