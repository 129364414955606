import { Injectable } from '@angular/core';
import { Cycles } from '../models/cycles';
import { DateService } from '../../shared/services/date.service';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CyclesEntityService extends BaseEntityService<Cycles> {

  constructor(
    httpClient: HttpClient,
    private dateService: DateService,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'cycle', plural: 'cycles' },
      routerParamsService
    );
    this.nameId = 'cycleId';
  }

  save(values: Cycles) {
    let { startDate, endDate, } = values;
    const { id, hasIncentive, incentiveWindowSize } = values;
    startDate = this.dateService.format(startDate, 'dateLocal', true, 'date');
    endDate = this.dateService.format(endDate, 'dateLocal', true, 'date');
    const body = {
      ...values,
      startDate,
      endDate,
      incentiveWindowSize: hasIncentive ? incentiveWindowSize : null,
    };

    if (id) {
      return this.update(body);
    }

    return this.add(body);
  }
}
